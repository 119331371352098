import React from "react";
import Layout from "../../../components/layout"
import SearchResultPage from "../../../templates/search-results-template";

const SearchResultResiRent = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    const [scroll, setScroll] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 60)
        })
    }, [])

    return (
        <div className='search-results-page'>
            <Layout>
                <section className={scroll ? "search-banner search-banner--fixed" : "search-banner"}>
                    <SearchResultPage
                        locationname="/property/to-rent/"
                        pcategorytype="residential"
                        multipcategorytype={['residential']}
                        ptype="lettings"
                        ptypetag="to-rent"
                        pstatustype={['sold-stc', 'sold', 'under-offer', 'to-let']}
                        fullpathone={fullpathone}
                        fullpathname={fullpath}
                        {...props}
                        psearch="Lettings"
                    />
                </section>
            </Layout>
        </div>
    );
};

export default SearchResultResiRent;